import { colors, MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";
import styled from "styled-components";

export const StyledIframe = styled.iframe`
  width: ${props => props.width ?? "100vw"};
  height: ${props => props.height ?? "56.25vw"};
  min-height: ${props => props.height ?? "100vh"};
  min-width: 250vh;
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const TextContainer = styled.div<{
  verticalAlign: string;
  horizontalAlign: string;
}>`
  position: absolute;
  z-index: 3;
  width: 41%;
  color: ${colors.white};
  opacity: 0.8;
  h2,
  h3,
  h4 {
    font-size: 27px;
    text-transform: uppercase;
    font-family: bold;
  }

  p {
    font-size: 20px;
    margin-bottom: 0px;
  }

  ${({ horizontalAlign }) =>
    //HORIZONTAL TEXT ALIGNMENT INSIDE CONTAINER

    horizontalAlign === "right"
      ? `right: 11%; text-align: right;`
      : horizontalAlign === "center"
      ? `left: 50%; transform: translateX(-50%); text-align: center;`
      : `left: 11%;`};

  ${({ verticalAlign }) =>
    //VERTICAL TEXT ALIGNMENT INSIDE CONTAINER

    verticalAlign === "top"
      ? `top: 14%;`
      : verticalAlign === "bottom"
      ? `bottom: 14%;`
      : `top: 50%; transform: translateY(-50%);`};

  ${({ horizontalAlign, verticalAlign }) =>
    /* CENTERING TEXT IF BOTH VERTICAL AND HORIZONTAL ARE CENTER */

    horizontalAlign === "center" &&
    verticalAlign === "center" &&
    `transform: translate(-50%, -50%);`};

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 76%;
    margin: auto;
    text-align: left;

    h2,
    h3 {
      font-size: 16px;
    }

    p {
      font-size: 14px;
    }
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    h4 {
      margin-bottom: auto;
    }
  }
`;

export const ButtonContainer = styled.div<{ horizontalAlign: string }>`
  display: flex;
  ${({ horizontalAlign }) =>
    // HORIZONTAL ALIGNMENT OF BUTTON TO MATCH TEXT ALIGNMENT
    horizontalAlign === "right"
      ? `justify-content: flex-end;`
      : horizontalAlign === "center"
      ? `justify-content: center;`
      : `justify-content: flex-start;`};

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    justify-content: flex-start;
  }
`;

export const MetricsContainer = styled.div<{ contentLength: number }>`
  display: grid;
  ${({ contentLength }) =>
    contentLength && `grid-template-columns: repeat(${contentLength},1fr);`};

  margin-top: 30px;
  margin-bottom: 50px;

  h2,
  h3 {
    font-size: 40px;
    margin-bottom: 0px;
  }

  p {
    font-size: 16px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    h2,
    h3 {
      font-size: 20px;
    }

    p {
      font-size: 16px;
    }
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: -20px;
    margin-bottom: -5px;
  }
`;

export const GradientOverlay = styled.div<{ alignment: string }>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  ${({ alignment }) =>
    alignment === "right"
      ? `
  background: linear-gradient(
    266.61deg,
    rgba(13, 22, 47, 0.7) 8.96%,
    rgba(13, 22, 47, 0) 100.12%
  );
`
      : alignment === "center"
      ? `background: rgba(13, 22, 47, 0.51);`
      : `background: linear-gradient(94.23deg, rgba(13, 22, 47, 0.8) 3.68%, rgba(13, 22, 47, 0) 83.13%);`}
`;
