import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Hero from "@components/shared/hero/hero";
import ProcessContentBlock from "@components/process/processContentBlock";
import { Query } from "@graphql-types";
import styled from "styled-components";
import _ from "lodash";
import SEO from "@components/shared/seo";
import { TABLET_BREAKPOINT } from "@util/constants";

function OurProcess() {
  const { sanityProcessPage }: Query = useStaticQuery(graphql`
    {
      sanityProcessPage {
        contentBlocks {
          blockTitle
          useVideo
          textAlignVertical
          textAlignHorizontal
          blockImage {
            asset {
              fluid {
                srcWebp
                srcSetWebp
                srcSet
                src
              }
            }
          }
          blockText {
            _rawContent
          }
          blockVideo {
            vimeoUrl
            uploadedVideo {
              asset {
                url
              }
            }
          }
          metrics {
            _rawContent
            _key
          }
          _key
        }
        heroBlock {
          ctaScrollButtonText
          heroBackgroundImage {
            asset {
              fluid(maxWidth: 3000) {
                srcWebp
                srcSetWebp
                srcSet
                src
              }
            }
          }
          heroHeader
          heroMobileBackgroundImage {
            asset {
              fluid {
                srcWebp
                srcSetWebp
                srcSet
                src
              }
            }
          }
          heroSubText {
            _rawContent
          }
          heroVideo {
            uploadedVideo {
              asset {
                url
              }
            }
          }
          useVideo
        }
        seoBlock {
          pageTitle
          slug {
            current
          }
          ogImage {
            asset {
              fluid {
                srcWebp
                srcSet
                srcSetWebp
                src
              }
            }
          }
          pageKeyWords
          pageDescription
        }
      }
    }
  `);

  if (sanityProcessPage == null) {
    return null;
  }
  const { heroBlock, contentBlocks, seoBlock } = sanityProcessPage;

  const [currentIndexInView, setCurrentIndexInView] = useState(0);

  const handleScroll = (value: number) => {
    const windowHeight = window.innerHeight;
    if (value > 0) {
      window.scrollTo({
        top: currentIndexInView + windowHeight,
        behavior: "smooth",
      });
      setCurrentIndexInView(currentIndexInView + windowHeight);
    } else if (value < 0) {
      window.scrollTo({
        top: currentIndexInView - windowHeight,
        behavior: "smooth",
      });
      setCurrentIndexInView(currentIndexInView - windowHeight);
    }
    document.removeEventListener("wheel", detectScroll);
  };

  const detectScroll = (e: WheelEvent) => {
    if (typeof window !== "undefined") {
      window.innerWidth < TABLET_BREAKPOINT ? null : handleScroll(e.deltaY);
    }
  };

  let initialY: number | null;

  function startTouch(e: TouchEvent) {
    initialY = e.touches[0].clientY;
  }

  function moveTouch(e: TouchEvent) {
    if (initialY === null) {
      return;
    }

    let currentY = e.touches[0].clientY;

    let diffY = initialY - currentY;

    // sliding vertically
    if (diffY > 0) {
      // swiped up
      handleScroll(1);
    } else {
      // swiped down
      handleScroll(-1);
    }

    initialY = null;
  }

  useEffect(() => {
    document.addEventListener("wheel", detectScroll, { once: true });
    // document.addEventListener("touchstart", startTouch, { once: true });
    // document.addEventListener("touchmove", moveTouch, { once: true });

    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }

    return () => {
      document.removeEventListener("wheel", detectScroll);
      // document.removeEventListener("touchstart", startTouch);
      // document.removeEventListener("touchmove", moveTouch);
    };
  }, []);

  useEffect(() => {
    let buffer = true;
    setTimeout(() => {
      buffer = false;
      if (!buffer) {
        document.addEventListener("wheel", detectScroll, { once: true });
        // document.addEventListener("touchstart", startTouch, { once: true });
        // document.addEventListener("touchmove", moveTouch, { once: true });
      }
    }, 1500);

    return () => {
      document.removeEventListener("wheel", detectScroll);
      // document.removeEventListener("touchstart", startTouch);
      // document.removeEventListener("touchmove", moveTouch);
    };
  });

  return (
    <>
      <SEO seoData={seoBlock} slug={"/production-process"} />
      <Hero heroContent={heroBlock} headerIsVisible fullHeight />
      <div>
        {contentBlocks.map((contentBlock, index) => {
          return (
            <ProcessContentBlock
              content={contentBlock}
              key={contentBlock?._key}
              id={contentBlock?._key}
            />
          );
        })}
      </div>
    </>
  );
}

export default OurProcess;
