import React, { useEffect, useRef, useState } from "react";
import BlockContent from "@sanity/block-content-to-react";
import { FluidObject } from "gatsby-image";
import styled from "styled-components";
import Vimeo from "@vimeo/player";
import VisibilitySensor from "react-visibility-sensor";

import {
  AnimationContainer,
  IconButton,
  Image,
  Video,
  VideoPopUp,
} from "@components/shared/sub";
import { SanityProcessVideo } from "@graphql-types";
import { animationTypes, vimeoUrl } from "@util/constants";
import { Container } from "@util/standard";
import {
  ButtonContainer,
  GradientOverlay,
  MetricsContainer,
  StyledIframe,
  TextContainer,
} from "./process.style";

export const VideoWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -99;
  overflow: hidden;
`;

interface Props {
  content: SanityProcessVideo;
  playOnload?: boolean;
  id: string;
  rendered?: boolean;
}

function ProcessContentBlock(props: Props) {
  const { content, playOnload, id, rendered } = props;
  const {
    blockImage,
    blockText,
    blockTitle,
    blockVideo,
    useVideo,
    metrics,
    textAlignHorizontal,
    textAlignVertical,
  } = content;

  const [videoPopUpOpen, setVideoPopUpOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  function onChange(isVisible: boolean) {
    setIsVisible(isVisible);
  }

  return (
    <>
      {/* <AnimationContainer
        animationType={animationTypes.slideInBottom}
        delay={1000}
      > */}

      <Container
        width="100vw"
        height="100vh"
        backgroundColor={!blockVideo && !blockImage ? "navy" : "transparent"}
        position="relative"
        id={id}
      >
        <GradientOverlay alignment={textAlignHorizontal as string} />

        {useVideo && blockVideo && (
          <Video
            id={blockVideo.vimeoUrl}
            url={blockVideo.vimeoUrl}
            volume={false}
            pause={true}
            isVisible={isVisible}
          />
        )}
        {blockImage && !useVideo && (
          <Image
            isBackground
            backgroundStyle={{
              width: "100%",
              height: "100%",
              position: "absolute",
            }}
            fluid={blockImage.asset?.fluid as FluidObject}
          />
        )}
        {blockText && (
          <VisibilitySensor partialVisibility={false} onChange={onChange}>
            <TextContainer
              horizontalAlign={textAlignHorizontal as string}
              verticalAlign={textAlignVertical as string}
            >
              <BlockContent blocks={blockText._rawContent} />
              {metrics && metrics.length > 0 && (
                <MetricsContainer contentLength={metrics.length}>
                  {metrics.map((metric) => {
                    return <BlockContent blocks={metric?._rawContent} />;
                  })}
                </MetricsContainer>
              )}
              {useVideo && blockVideo && (
                <ButtonContainer
                  horizontalAlign={textAlignHorizontal as string}
                >
                  <IconButton
                    fontSize={15}
                    margin="20px 0px 0px 0px"
                    buttonText="Watch Full Video"
                    onClick={() => setVideoPopUpOpen(true)}
                  />
                </ButtonContainer>
              )}
            </TextContainer>
          </VisibilitySensor>
        )}
      </Container>

      {/* </AnimationContainer> */}

      {useVideo && blockVideo && (
        <VideoPopUp
          videoUrl={blockVideo.vimeoUrl as string}
          playOnOpen
          visible={videoPopUpOpen}
          setVisible={setVideoPopUpOpen}
        ></VideoPopUp>
      )}
    </>
  );
}

export default ProcessContentBlock;

{
  /* <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script> */
}
